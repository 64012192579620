import React, { useCallback, useMemo, useState } from 'react';

import { useCurrentUser, DataProvider, Filtering } from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import { useQuery } from 'react-query';
import { Supplier } from 'types';
import { SupplierList, FILTER_DEFINITIONS } from 'v2components';

const SupplierListView: React.FC = () => {
  const { companyId } = useCurrentUser();

  const [showOnlyArchived, setShowOnlyArchived] = useState<boolean>(
    () => localStorage.getItem('sqm-fe-list-suppliers-archive-toggle') === 'true',
  );

  const setShowOnlyArchivedCallback = useCallback((showOnlyArchived: boolean) => {
    localStorage.setItem('sqm-fe-list-suppliers-archive-toggle', showOnlyArchived.toString());
    setShowOnlyArchived(showOnlyArchived);
  }, []);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['suppliers', companyId, showOnlyArchived],
    queryFn: () =>
      showOnlyArchived
        ? supplierApi.getAllSuppliersByCompanyAndStatus(companyId, 'ARCHIVED')
        : supplierApi.getAllByCompany(companyId),
  });
  const suppliers = useMemo(() => (isFetching ? [] : data ?? []), [data, isFetching]);
  const approvedSuppliers = useMemo(() => suppliers?.filter((supplier) => supplier.status === 'APPROVED'), [suppliers]);
  return (
    <Filtering.FilterProvider searchTermKey="search" definitions={FILTER_DEFINITIONS}>
      <DataProvider.FixedWithAutoPagination data={suppliers} isLoading={isFetching} filterOption={filterSupplier}>
        <SupplierList
          showOnlyArchived={showOnlyArchived}
          setShowOnlyArchived={setShowOnlyArchivedCallback}
          approvedSuppliers={approvedSuppliers}
          refetchSuppliers={refetch}
        />
      </DataProvider.FixedWithAutoPagination>
    </Filtering.FilterProvider>
  );
};

export default SupplierListView;

const filterSupplier = ((
  item: Readonly<Supplier>,
  searchTerm: string | undefined,
  filters: Filtering.ResolvedFilters<typeof FILTER_DEFINITIONS> | undefined,
) => {
  if (searchTerm && !item.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
    return false;
  }

  if (filters && Object.values(filters).some((f) => f.value)) {
    const { supplierType, status, riskType } = filters;
    return (
      (!supplierType?.value || item.supplierType?.id === supplierType?.value) &&
      (!status?.value || item.status === status.value) &&
      (!riskType?.value || item.riskType?.id === riskType.value)
    );
  }

  return true;
}) as DataProvider.FilterOptionFn<Supplier>;
